import { ApiPromise, WsProvider } from '@polkadot/api';
let api
async function init() {
    if(api !== undefined) {
        return;
    }
    api = await ApiPromise.create({provider: new WsProvider("wss://blockchain.hellogreenmarket.com")});
}


export async function getPart(id) {
    try {
        await init();
        const parts = await api.query.supplyChain.components(id);
        return parts;
    } catch(e) {
        console.error(e.message);
    }
}

export async function getChainInfo() {
    if(!api) {
        await init();
    }

    // Retrieve the chain name
    const chain = await api.rpc.system.chain();
    const lastHeader = await api.rpc.chain.getHeader();
    return `${chain}: last block #${lastHeader.number} has hash ${lastHeader.hash}`;
}