import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPart } from "../provider/data";
import { TableVirtuoso } from "react-virtuoso";
import { clsx } from "clsx";

const didJWT = require("did-jwt");

function formatDate(timestamp) {
  let timestampString = timestamp.toJSON();
  let dateStamp = new Date(timestampString);
  return dateStamp.toLocaleString();
}

export function Part() {
  let { partId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [part, setPart] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const data = await getPart(partId);
      // console.log(data.componentOf);
      setPart(data);
      setIsLoading(false);
    };

    fetchData();
  }, [partId]);

  return (<div className="container pb-45">
    {isLoading ? <div>Loading...</div> : !part ? <div>Part not found</div> : <h2>{partId}</h2>}
    {part && part.audits && (<div className="container">
      {/*<h4>Supply Chain</h4>*/}
      <h4>Data</h4>
      <table className="table">
        <thead>
        <tr>
          <th>Step</th>
          <th>Date + Time</th>
          <th>Metadata</th>
        </tr>
        </thead>
        <tbody>
        {part.audits.map((e, i) => {
          return (<tr key={i}>
            <td>{i + 1}</td>
            <td>{formatDate(e.timestamp)}</td>
            <td>
              <pre>{`${JSON.stringify(didJWT.decodeJWT(e.auditData), undefined, 2)}`}</pre>
            </td>
          </tr>);
        })}
        </tbody>
      </table>
    </div>)}
    {part && part.components && (<div className="container pb-45">
      <h4>Components</h4>
      <TableVirtuoso
        style={{ height: Math.min(600, (part.components.length * 45) + 80) }}
        fixedHeaderContent={() => (<tr>
          <th>PartId</th>
          <th>PartName</th>
        </tr>)}
        components={{
          Table: ({ className, ...props }) => <table {...props} className={clsx(className, "table")} />,
        }}
        data={part.components}
        itemContent={(index, e) => {
          return (<>
            <td>{index}</td>
            <td>
              <a href={`/${e}`}>{e}</a>
            </td>
          </>);
        }}
      />
    </div>)}
    {part && part.componentOf && (<div className="container">
      <h4>Component of</h4>
      <TableVirtuoso
        style={{ height: Math.min(600, (part.componentOf.length * 45) + 80) }}
        fixedHeaderContent={() => (<tr>
          <th>PartId</th>
          <th>PartName</th>
        </tr>)}
        components={{
          Table: ({ className, ...props }) => <table {...props} className={clsx(className, "table")} />,
        }}
        data={part.componentOf}
        itemContent={(index, e) => {
          return (<>
            <td>{index}</td>
            <td>
              <a href={`/${e}`}>{e}</a>
            </td>
          </>);
        }}
      />
    </div>)}
  </div>);
}
