import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Part } from "./components/Part";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ArNavBar } from "./components/NavBar";

function App() {
  return (
    <>
      <ArNavBar />
      <div className="container">
        <Router>
          <Routes>
            <Route path="/:partId" element={<Part />} />
          </Routes>
        </Router>
      </div>
    </>
  );
}

export default App;
