import { useEffect, useState } from "react";
import { Container, Navbar } from "react-bootstrap";
import { getChainInfo } from "../provider/data";

export function ArNavBar() {
  const [chainInfo, setChainInfo] = useState(false);

  useEffect(() => {
    setInterval(() => {
      getChainInfo().then((e) => setChainInfo(e));
    }, 5000);
    getChainInfo().then((e) => setChainInfo(e));
  });

  return (
    <>
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="#home">
            {chainInfo && (
              <span className="text-info float-left">{chainInfo}</span>
            )}
          </Navbar.Brand>
        </Container>
      </Navbar>
    </>
  );
}
